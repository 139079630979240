.tab-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.tab-panel .tab {
    color: var(--gray-dark);
    font-size: 0.75rem;
    padding: 0.625rem 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: 0.1s;
    font-weight: 500;
    line-height: 1.25rem;
    width: 100%;
    min-width: fit-content;
}
.tab-panel .tab__fixed .tab {
    flex: 1;
}
.tab-panel .tab:focus {
    background-color: transparent;
    outline: none;
}
.tab-panel .tab__active {
    color: var(--primary);
}
.tab-panel .tab__hidden {
    display: none;
}
.tab-panel .tab:not(.tab__disabled) {
    cursor: pointer;
}
.tab-panel .tab__disabled {
    opacity: 0.2;
}
.tab-panel .tab__pointer {
    background: var(--primary);
    bottom: 1px;
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}
.tab-panel__navigation-container {
    display: flex;
    margin-bottom: calc(1px * 5);
    width: 100%;
    z-index: 20;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: var(--background);
}

.tab-panel__navigation-container::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.tab-fixed-top {
    top: 68px
}

.tab-panel__navigation-container-navigation {
    /*box-shadow: inset 0 -1px var(--primary);*/
    display: flex;
    width: 100%;
    position: relative;
}

.tab-panel--fixed .tab-panel__navigation-container-navigation {
    flex: 1;
}
.tab-panel .tab-content {
    display: flex;
    flex-direction: column;
    position: relative;
}
.tab-panel .tab-content:not(.tab-content__active) {
    display: none;
}
.tab-panel .tab-content__active {
    display: flex;
}
 