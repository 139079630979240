/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'PT Sans', serif;
    background: var(--bottom-nav);
}

.Toastify__toast-icon {
    margin-bottom: 8%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 40px var(--auth-plat-bg) inset !important;
    -webkit-text-fill-color: var(--text-base);
}

input {
    caret-color: var(--primary);
}

pre {
    font-family: 'Noto Sans', serif !important;
}

.fullheight {
    height: calc(var(--vh, 1vh) * 100);
}


.more-menu-list-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.primary-dashed-border {
    overflow: hidden;
    position: relative;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
}

.primary-dashed-border:before {
    content: "";
    position: absolute;
    border: 5px dashed var(--primary);
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
}

.error-dashed-border {
    overflow: hidden;
    position: relative;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
}

.error-dashed-border:before {
    content: "";
    position: absolute;
    border: 5px dashed var(--error);
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
}


.more-menu-list-wrapper::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

@keyframes moveToRight {
    from {
        left: 0;
    }

    to {
        left: 100%;
    }
}

.progress-outer {
    display: flex;
    align-items: center;
}

.progress-info {
    width: 30px;
    padding-left: 5px;
}

.progress {
    width: 100%;
    background: var(--progress-track);
    min-height: 1px;
    position: relative;
    overflow: hidden;
}

.progress.progress--small {
    height: 3px;
    border-radius: 3px;
}

.progress.progress--medium {
    height: 5px;
    border-radius: 5px;
}

.progress.progress--large {
    height: 10px;
    border-radius: 10px;
}

.progress-bar {
    background: var(--primary);
    height: 100%;
    position: absolute;
    left: 0;
}

.progress.progress--loading .progress-bar {
    width: 30%;
    animation: moveToRight 1s infinite linear;
    border-radius: 10px;
}

@layer utilities {
    .content-height-with-navbar {
        @apply h-[calc(100vh-4rem-1px)]
    }

    /*@media screen and (max-width: 768px) {*/
    /*    .content-with-header-and-nav {*/
    /*        @apply  !h-[calc(100vh-7rem-1px)];*/
    /*    }*/
    /*}*/

    .content-with-header-and-nav {
        @apply h-[calc(100vh-4rem-1px)];
    }

    .wallet-confirm-modal-text {
        @apply text-[12px] leading-[12px] flex justify-start items-center text-white font-pt-sans;
    }

    .wallet-confirm-modal-values {
        @apply text-[17px] leading-[21px] flex justify-end items-center text-volts-wallet font-pt-mono;
    }

    .loading-dots:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4, end) 900ms infinite;
        animation: ellipsis steps(4, end) 900ms infinite;
        content: "\2026";
        /* ascii code for the ellipsis character */
        width: 0;
    }

    .volts-wallet__button {
        @apply flex justify-between items-center w-[125px] text-base text-dark-main h-9 rounded-[18px] pl-3 pr-2.5 outline-none disabled:bg-gray-dark disabled:border-gray-dark
    }

    .volts-wallet__button-icon {
        @apply rounded-full bg-dark-main p-0.5 w-[18px] h-[18px] flex justify-center items-center
    }

    @keyframes ellipsis {
        to {
            width: 20px;
        }
    }

    @-webkit-keyframes ellipsis {
        to {
            width: 20px;
        }
    }

    @keyframes expand-left {
        from {
            width: 0;
        }

        to {
            width: 35.125rem;
        }
    }

    @-webkit-keyframes expand-left {
        from {
            width: 0;
        }

        to {
            width: 35.125rem;
        }
    }

    .expanding-container {
        animation: expand-left 500ms;
        /*27.125 rem;*/
        width: 35.125rem;
    }

    @keyframes ping {
        75%, 100% {
            transform: scale(2);
            opacity: 0;
        }
    }

    .animating-move-down {
        -webkit-animation: moveDown 0.25s linear;
        animation: moveDown 0.25s linear;
        transform: translateY(70px);
    }

    .animating-move-up {
        -webkit-animation: moveUp 0.25s linear;
        animation: moveUp 0.25s linear;
        transform: translateY(-70px);
    }

    @keyframes moveDown {
        0% {
            transform: translateY(0px)
        }

        100% {
            transform: translateY(70px)
        }
    }

    @keyframes moveUp {
        0% {
            transform: translateY(70px)
        }

        100% {
            transform: translateY(-70px)
        }
    }

    .animate-ping {
        animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    }
}

@media screen and (max-width: 768px) {
    .content-with-header-and-nav {
        height: calc(calc((var(--vh, 1vh) * 100) - 7.5rem - 1px)) !important;
    }
}

.search-result-container-with-results::-webkit-scrollbar {
    /*background: var(--global-search-result-item);*/
    /*height: 4px;*/
    /*width: 4px;*/
}

.search-result-container-with-results::-webkit-scrollbar-thumb {
    /*background: transparent;*/
    /*border-radius: 10px;*/
}

.search-result-item__button:nth-last-child(2) {
    margin-bottom: 0;
}

.notification-item-last-no-border:nth-last-child(2) {
    border: none;
}

/* Post content  */
.post-cont-enter {
    transform: translateX(0%);
    transition: transform var(--slide-animation-timer) ease-in-out;
}

.post-cont-enter-active {
    transform: translateX(0%);
    transition: transform var(--slide-animation-timer) ease-in-out;
}

.post-cont-exit {
    transform: translateX(-100%);
    transition: transform var(--slide-animation-timer) ease-in-out;
}

.post-cont-exit-done {
    transform: translateX(-100%);
}

/* Stream picker content  */
.stream-cont {
    transform: translateX(100%);
    position: absolute;
    top: 0;
    left: 0;
}

.stream-cont-enter {
    transform: translateX(0%);
    transition: transform var(--slide-animation-timer) ease-in-out;
}

.stream-cont-enter-done {
    transform: translateX(0%);
}

.stream-cont-enter-active {
    transform: translateX(0%);
    transition: transform var(--slide-animation-timer) ease-in-out;
}

.stream-cont-exit {
    transform: translateX(100%);
    transition: transform var(--slide-animation-timer) ease-in-out;
}

.stream-cont-exit-done {
    transform: translateX(100%);
}

/* Tag Content  */
.tag-cont {
    transform: translateX(100%);
    position: absolute;
    top: 0;
    left: 0;
}

.tag-cont-enter {
    transform: translateX(0%);
    transition: transform var(--slide-animation-timer) ease-in-out;
}

.tag-cont-enter-done {
    transform: translateX(0%);
}

.tag-cont-enter-active {
    transform: translateX(0%);
    transition: transform var(--slide-animation-timer) ease-in-out;
}

.tag-cont-exit {
    transform: translateX(100%);
    transition: transform var(--slide-animation-timer) ease-in-out;
}

.tag-cont-exit-done {
    transform: translateX(100%);
}

.height-transition {
    transition: height var(--slide-animation-timer);
}

.recent-post-line-clamp-3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.filter-list::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.filter-list::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
}

/* Handle */
.filter-list::-webkit-scrollbar-thumb {
    background: var(--accent-2);
    border-radius: 10px;
}

/* Handle on hover */
/*.filter-list::-webkit-scrollbar-thumb:hover {*/
/*    background: #b30000;*/
/*}*/

.filter-list::-webkit-scrollbar-corner {
    /*
    background-image: url(resources/corner.png);
    background-repeat: no-repeat;
    */
    background-color: transparent;
}

.ego {
    --background: #FFFFFF;
    --background-secondary: #F5F5F5;
    --background-primary: #FFFFFF;
    --background-tertiary: #F0F1F4;
    --background-quad: #FFFFFF;
    --background-comment: #F0F1F4;
    --background-light-secondary: #A5B5C5;
    --dark-background: #212121;
    --background-dark-main: #212636;
    --credo-black: #10121A;
    --modalTransparentBackground: rgba(245, 245, 245, 0.85);
    --userRatingOverlayBackground: rgba(245, 245, 245, 0.5);
    --darker-background: #F5F5F5;

    --primary: #00A0FF;
    --accent: #0BC7E0;
    --accent-2: #0BE0D8;
    --error: #F03C11;
    --error-2: #D0021B;

    --turquoise-400: #00FFCC;

    --text-base: #212636;
    --text-title: #000000;
    --shadow: #F5F5F5;
    --word: #212636;
    --white-light: #F5F5F5;
    --white: #FFFFFF;
    --dark-timestamp: #212636;

    --app-dark: #10121A;

    --gray-dark: #96A5B9;
    --gray-light: #E6E8ED;
    --gray-lighter: #F0F1F4;
    --gray-darker: #9B9B9B;

    --ox-blue: #3B455A;
    --ebony-clay: #283041;

    --divider: #283041;
    --divider-2: #BABAD1;
    --divider-3: #F0F1F4;

    --switch-border-color: #479EF8;
    --profile-border: #E1E6EB;
    --hover-1: #F5F5F5;
    --hover-2: var(--hover-1);
    --post-bar-border: #E1E6EB;

    --auth-plat-bg: var(--gray-lighter);
    --input-bg: var(--gray-lighter);

    /* Left Menu */
    --menu-bg: #F0F1F4;

    /* Mobile view colors */
    /* Left Menu */
    --mobile-menu-bg: var(--menu-bg);

    --background-skeleton: #F5F5F5;
    --hightlight-skeleton: #EEEEEE;
    /* Banner */
    --banner-bg: #212121;
    /* Bottom Nav bar */
    --bottom-nav: #212121;

    --modal: rgba(192, 201, 213, 0.97);

    --background-badge: #D0021B;
    --text-badge: #FFFFFF;

    --tag-remove: #96A5B9;

    --shared-post: #F0F9FF;
    --global-search-result-item: #F0F1F4;

    --rating-card: #8CD4FF;
    --rating-card-text: #171C2D;
    --profile_overlay: #212636;
    --notif-badge: var(--background);

    --progress-track: #F0F1F4;

    /* tags */
    --tag-green: #0FF077;
    --tag-unrated: #171C2D;
    --off-white: #F5F5F5;
    --tag-selected: #F5F5F5;
    --proposed-tag-color: #171C2D;

    /* white in credo and black in ego */
    --bw: #000000;

    --slide-animation-timer: 250ms;

    /* Credo graph */
    --credo-graph-container-bg: #cde1db;
    --credo-graph-score-text: black;
    --credo-graph-no-data-bg: #171C2D;
    --credo-graph-blur-bg: rgb(18 28 38 / 80%);

    /* Author Indicator */
    --auth-indicator-border: #a5b7c4;
	--social-icon-color: #C0C9D5;

    /* Boost post */
    --boost-post-bottom-border: var(--divider-2);
    --boost-tag-accent: #82E3FF;

    /* Volts */
    --volts-wallet: #FF8C01;
    --volts-in-boost: var(--tag-green);
    --volts-boost-rank: #12233e;
    --volts-text-1: #8B4C00;

    --content-L1: #EEEEEE;
	--content-L2: #DEE0EC;

}

.credo {
    --background: #10121A;
    --background-secondary: #000000;
    --background-primary: #171C2D;
    --background-tertiary: var(--background-primary);
    --background-quad: #10121A;
    --background-comment: #283041;
    --background-light-secondary: #A5B5C5;
    --dark-background: #212121;
    --background-dark-main: #212636;
    --credo-black: #10121A;
    --modalTransparentBackground: rgba(33, 38, 54, 0.85);
    --userRatingOverlayBackground: rgba(23, 28, 45, 0.5);
    --darker-background: #10121A;

    --primary: #00A0FF;
    --accent: #0BC7E0;
    --accent-2: #0BE0D8;
    --error: #F03C11;
    --error-2: #D0021B;

    --turquoise-400: #00FFCC;

    --text-base: #F5F5F5;
    --text-title: #F5F5F5;
    --shadow: #171C2D;
    --word: #F5F5F5;
    --white-light: #F5F5F5;
    --white: #FFFFFF;
    --dark-timestamp: #212636;

    --app-dark: #10121A;

    --gray-dark: #96A5B9;
    --gray-light: #E6E8ED;
    --gray-lighter: #F0F1F4;
    --gray-darker: #9B9B9B;

    --ox-blue: #3B455A;
    --ebony-clay: #283041;

    --divider: #283041;
    --divider-2: #BABAD142;
    --divider-3: var(--divider);
    --hover-1: #212636;
    --hover-2: var(--background-primary);

    --auth-plat-bg: var(--ebony-clay);
    --input-bg: var(--ebony-clay);

    /* Left Menu */
    --menu-bg: var(--background-primary);

    --switch-border-color: #0BC7E0;
    --profile-border: #283041;
    --post-bar-border: #171C2D;
    /* Mobile view colors */
    /* Left Menu */
    --mobile-menu-bg: #283041;

    --background-skeleton: #1D2233;
    --hightlight-skeleton: #171C2D;
    /* Banner */
    --banner-bg: #212121;
    /* Bottom Nav bar */
    --bottom-nav: #212121;

    --modal: rgba(23, 28, 45, 0.97);

    --background-badge:#D0021B;
    --text-badge: #171C2D;
    --tag-remove: #F5F5F5;
    --shared-post: #363D4E;
    --global-search-result-item: var(--shared-post);
    --rating-card: var(--primary);
    --rating-card-text: #171C2D;
    --profile_overlay: #212636;
    --notif-badge: var(--accent);

    --progress-track: #283041;

    /* tags */
    --tag-green: #0FF077;
	--tag-unrated: #171C2D;
    --off-white: #F5F5F5;
    --tag-selected: #171C2D;
    --proposed-tag-color: #F0F1F4;

    /* white in credo and black in ego */
    --bw: #FFFFFF;

    --slide-animation-timer: 250ms;

    /* Credo graph */
    --credo-graph-container-bg: black;
    --credo-graph-score-text: #0BE0D8;
    --credo-graph-no-data-bg: #121C26;
    --credo-graph-blur-bg: rgba(18, 28, 38, 0.65);

    /* Author Indicator */
    --auth-indicator-border: #a5b7c4;
    --social-icon-color: #C0C9D5;

    /* Boost post */
    --boost-post-bottom-border: #000000;
    --boost-tag-accent: #82E3FF;

    /* Volts */
    --volts-wallet: #FF8C01;
    --volts-in-boost: var(--tag-green);
    --volts-boost-rank: #12233e;
    --volts-text-1: #8B4C00;

    --content-L1: #181C28;
	--content-L2: #212636;
}
