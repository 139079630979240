.item-bg-ego {
    background-color: #FFFFFF;
}

.item-bg-ego .profile-pic-wrapper img {
    border-color: #e1e6eb;
}

.item-bg-credo {
    background-color: #10121A;
}

.item-bg-credo .profile-pic-wrapper img {
    border-color: #283041;
}

.item-text-credo {
    color: #f5f5f5;
}

.item-text-ego {
    color: #212636;
}

.border-bottom-ego {
    border-bottom: 1px solid #e1e6eb;
}

.border-bottom-credo {
    border-bottom: 1px solid #171c2d;
}

.background-border-ego {
    background-color: #e1e6eb;
}

.background-border-credo {
    background-color: #96A5B9;
}


