/* React cropper */
.react-crop__fit-height {
    height: 100%;
}

.react-crop__fit-height > .ReactCrop__child-wrapper {
    height: 100%;
}

.react-crop__fit-width {
    width: 100%;
}

.react-crop__fit-width > .ReactCrop__child-wrapper {
    width: 100%;
}
