/* Volts Summary */
.volts-summary-grid {
    display: grid;
    grid-template-columns: [first] 110px [second] 70px [third] 60px [fourth] 40px [end];
    grid-template-rows: repeat(3, 24px [col-start]);;
    grid-auto-flow: column;
    column-gap: 10px;
    row-gap: 4px;
}

/* Volts Wallet history */
.volts-history-grid {
    display: grid;
    grid-template-columns: [first] 78px [second] 88px [third] 90px [fourth] 60px [end];
    grid-auto-rows: 24px;
    font-family: "PT Sans Narrow", "Noto Sans", sans-serif;
    width: 320px;
    column-gap: 2px;
}

/* Volts Boost history */
.volts-boost-grid {
    display: grid;
    grid-template-columns: [first] 78px [second] 100px [third] 38px [fourth] 48px [fifth] 50px [end];
    grid-auto-rows: 24px;
    font-family: "PT Sans Narrow", "Noto Sans", sans-serif;
    width: 320px;
    column-gap: 2px;
}

.volts-boost-tbody,
.volts-history-tbody {
    /* Body plus header should be 288px because spinner is 288px */
    height: 262px;
}

@media screen and (max-width: 425px) {
    .volts-boost-grid,
    .volts-history-grid {
        width: 262px;
    }
    .volts-history-grid {
        grid-template-columns: [first] 68px [second] 70px [third] 68px [fourth] 48px [end];
    }
    .volts-boost-grid {
        grid-template-columns: [first] 53px [second] 85px [third] 38px [fourth] 38px [fifth] 38px [end];
    }
    .volts-summary-grid {
        grid-template-columns: [first] 110px [second] 60px [third] 50px [fourth] 40px [end];
        grid-auto-flow: column;
        column-gap: 5px;
        row-gap: 5px;
    }
}

.ego .header-widget-carousel #volts-balance-widget-score {
    fill: var(--credo-graph-score-text) !important;
}

.wallet-confirmation-fees-grid {
    display: grid;
    grid-template-columns: [first] 80px [second] 10px [end];
    grid-auto-rows: 24px;
    /*font-family: "PT Mono", "Noto Sans", sans-serif;*/
    width: 90px;
    column-gap: 4px;
    row-gap: 2px;
}

.volts-wallet-transactions {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: 4px;
}
.volts-wallet-transactions::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
}

.volts-wallet-transactions::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: var(--volts-wallet);
    border-radius: 4px;
}

.volts-withdraw__input {
    outline: none;
}

/* Chrome, Safari, Edge, Opera */
.volts-withdraw__input::-webkit-outer-spin-button,
.volts-withdraw__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.volts-withdraw__input[type=number] {
    -moz-appearance: textfield;
}

.wallet-deposit-confirmation-grid {
    display: grid;
    grid-template-columns: [first] 72px [second] 10px [end];
    grid-auto-rows: 24px;
    /*font-family: "PT Mono", "Noto Sans", sans-serif;*/
    width: 82px;
    column-gap: 0;
    row-gap: 2px;
}
