/**
* Cards on feed page
* */

.explore-comm-card-container {
    padding-left: 7rem;
    padding-right: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.explore-comm-text-container {
    padding-left: 1.25rem;
}

.explore-comm-icon {
    width: 5rem;
    height: 5rem;
}

.explore-comm-card {
    padding-left: 2rem;
    padding-right: 2rem;
    width: 416px;
}

.explore-comm-card button {
    width: 100%;
}

.explore-comm-text {
    font-size: 16px;
    max-width: 16rem;
}

.uxp-rating-card,
.sugg-conn-card {
    max-width: 26rem;
}

@media screen and (max-width: 370px) {
    .explore-comm-icon {
        display: none !important;
    }
}

@media screen and (max-width: 440px) {
    .explore-comm-card-container {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
}

@media screen and (max-width: 480px) {
    .explore-comm-text {
        font-size: 12px;
        max-width: 100%;
    }

    .explore-comm-card-container {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .explore-comm-card {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .explore-comm-text-container {
        padding-left: 0;
        max-width: 200px;
    }
}

@media screen and (max-width: 526px) {
    .explore-comm-card {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .explore-comm-text-container {
        padding-left: 0.5rem;
    }
}

@media screen and (max-width: 624px) {
    .explore-comm-card-container {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
