:root {
    --header-widget-hover: 500ms;
    --header-widget-translate-init: 50px;
    --header-widget-translate-slide-out: 30px;
    --header-widget-translate-without-slide: 24px;
}

.header-widget-init {
    transform: translateX(var(--header-widget-translate-without-slide));
}

.header-widget-hover {
    transform: translateX(var(--header-widget-translate-init));
}

.header-widget-hover-enter {
    transform: translateX(var(--header-widget-translate-slide-out));
    transition: transform var(--header-widget-hover) ease-in-out;
}

.header-widget-hover-enter-done {
    transform: translateX(var(--header-widget-translate-slide-out));
    transition: transform var(--header-widget-hover) ease-in-out;
}

.header-widget-hover-exit {
    transform: translateX(var(--header-widget-translate-init));
    transition: transform var(--header-widget-hover) ease-in-out;
}

.header-widget-hover-exit-done {
    transform: translateX(var(--header-widget-translate-init));
}
