.react-tel-input {
    font-family: 'Noto Sans', serif;
}

.react-tel-input .flag-dropdown  {
    background-color: transparent !important;
    border: none !important;
}

.react-tel-input .flag-dropdown .selected-flag:hover,
.react-tel-input .flag-dropdown .selected-flag {
    background-color: transparent !important;
    border: none !important;
}

.react-tel-input .flag-dropdown .country-list {
    background-color: var(--auth-plat-bg);
    color: var(--text-base);
    margin: 0;
    max-height: 196px;
}

.decreased-country-list-height {
    max-height: 148px !important;
}

.react-tel-input .flag-dropdown .country-list li:hover,
.react-tel-input .flag-dropdown .country-list .highlight {
    background-color: var(--background);
}

.react-tel-input .flag-dropdown .country-list::-webkit-scrollbar {
    width: 6px;
}

.react-tel-input .flag-dropdown .country-list::-webkit-scrollbar-track {
    background-color: transparent;
}
.react-tel-input .flag-dropdown .country-list::-webkit-scrollbar-thumb {
    background-color: var(--primary) ;
    border-radius: 20px;
    /*border: 3px solid black;*/
}

.react-tel-input .flag-dropdown .selected-flag {
    width: 32px;
}
.react-tel-input .flag-dropdown .arrow {
    display: none;
}

.react-tel-input .selected-flag .flag {
    top: 52%;
}

.react-tel-input .selected-flag {
    padding: 0 0 0 10px;
}

.react-tel-input .form-control {
    padding-left: 40px;
    height: 40px;
}

