@import "phone-input-override.css";
@import "tabs.css";
@import "carousel.css";
@import "feed.css";
@import "notification-page.css";
@import "credo-graph.css";
@import "react-image-crop.css";
@import "volts-dashboard.css";
@import "header-widget.css";

.engage-tab-header::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

/**
* https://developer.mozilla.org/en-US/docs/Web/CSS/env
**/
.safe-area-bottom-padding {
    padding-bottom: env(safe-area-inset-bottom, 20px);
    transition: 0.5s all ease;
}

@media screen and (max-width: 650px) {
    .explore-item__text {
        /* 84: Right button, 40: Stream Image, 32: left-right padding, 16: left text padding */
        width: calc(100vw - 84px - 40px - 32px - 16px);
    }
}

@keyframes invisible-delay {
    0% {
        transform: translateY(70px);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 0;
    }
}

.invisible-delay-animation {
    transform: translateY(0);
    visibility: visible;
    animation: invisible-delay 3s;
}

@keyframes fadeIn {
    0% {
        visibility: hidden;
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
}

.visible-fade-in-animation {
    animation: fadeIn 0.4s linear;
    animation-fill-mode: forwards;
    visibility: hidden;
    opacity: 0;
}

.accent-2-scrollbar::-webkit-scrollbar {
    width: 6px;
}

.accent-2-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}

.accent-2-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--turquoise-400) ;
    border-radius: 20px;
    /*border: 3px solid black;*/
}

@media screen and (max-width: 425px) {
    .fixed_header {
        width: 265px;
    }
    .fixed_header tbody {
        width: 277px;
    }
}

.full-width {
    width: 100vw;
}

